import React, { useState, useEffect } from "react"
import styled from "styled-components"
import SearchBox from "components/searchBox"
import JobList from "components/jobList"
import ConfirmationForm from "components/form"


import { detectMobileAndTablet, isSSR } from "utils/"
import togglerIcon from "content/images/filters.svg"
import MinDropzone from "components/minDropzone"
import ProfileCard from "components/searchBox/profileCard"
import { language } from "config"



const StyledWrapper = styled.div`
  position: relative;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justif-content: flex-start;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-template-columns: 23.528rem auto;
    grid-gap: 2px;
  }
`

const StyledControl = styled.div`
  padding: 20px 1rem;

  .label {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: 11px;
  }

  .input {
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 4px;
    background: #FFFFFF;
    position: relative;
    outline: none;
    width: 100%;
    padding-left: 15px;
    ::placeholder {
      color: #9CB6D0;
      font-size: 13px;
      line-height: 16px;
    }
  }
`

const Container = React.memo(
  ({
    data,
    addProfile,
    removeProfile,
    fetchJobs,
    fetchJobsCategory,
    fetchJobsCategory1,
    fetchJobsCategory2,
    fetchJobsCategory3,
    fetchJobsCategory4,
    jobs,
    jobsCategory,
    jobsCategory1,
    jobsCategory2,
    jobsCategory3,
    jobsCategory4,
    queryObject,
    boardFilters,
    currentTab,
    updateCurrentTab,
    otherBoardFilters,
    profile,
    file,
    confirmationModal, 
    showConfirmationModal,
    welcomeModal,
    showWelcomeModal,
    putProfile,
    getProfile,
    tagging
  }) => {
    const [isSearchBoxShown, setIsSearchBoxShown] = useState(false)
    const [windowWidth, setWindowWidth] = useState(0)
    const [renderDrop, setRenderDrop] = useState(false)
  
    useEffect(() => {
      let handleWindowSizeChange
      // if (isSSR) is necessary to prevent error during the gatsby build
      if (!isSSR) {
        handleWindowSizeChange = () => setWindowWidth(window.innerWidth)
        // set initial innerWidth when component mounts
        setWindowWidth(window.innerWidth)
      }
      // Add event listener to update windowWidth in state
      window.addEventListener("resize", handleWindowSizeChange)
      return () => window.removeEventListener("resize", handleWindowSizeChange)
    }, [windowWidth])
  
    useEffect(() => {
      if (isSearchBoxShown) {
        document.body.className = 'search-open';
      } else {
        document.body.className = '';
      }
    }, [isSearchBoxShown])
    
    useEffect(() => {
      fetchJobs({boardFilters, page: 1, init: true, first: true})
      window.setTimeout(() => {
        setRenderDrop(true)
      }, 10)
    }, [])


    const confirmWelcome = (value) => {
      if(value) {
        console.log('profile', profile)
        putProfile({profile: profile.payload, action: 'init'})
      } else {
        showWelcomeModal(false)
        showConfirmationModal(false)

      }
    }
    return (
      <StyledWrapper>
        {((!detectMobileAndTablet(windowWidth) && windowWidth !== 0) || isSearchBoxShown) && (
          <SearchBox
            putProfile={putProfile}
            getProfile={getProfile}
            addProfile={addProfile}
            profile={profile}
            boardFilters={boardFilters}
            currentTab={currentTab}
            fetchJobs={fetchJobs}
            jobsCategory={jobsCategory}
            fetchJobsCategory={fetchJobsCategory}
            fetchJobsCategory1={fetchJobsCategory1}
            fetchJobsCategory2={fetchJobsCategory2}
            fetchJobsCategory3={fetchJobsCategory3}
            fetchJobsCategory4={fetchJobsCategory4}
            file={file}
            removeProfile={removeProfile}
            isMobile={detectMobileAndTablet(windowWidth)}
            hideSearchBox={() => setIsSearchBoxShown(false)}
            confirmationModal={confirmationModal}
            showConfirmationModal={showConfirmationModal}
            welcomeModal={welcomeModal}
            showWelcomeModal={showWelcomeModal}
            tagging={tagging}

          />
        )}
        {detectMobileAndTablet(windowWidth) && renderDrop && (
          <>
          {file && file.fileName && profile.payload?.info && (
            <StyledControl>
              <div className="label">
                Bienvenue {profile?.payload?.info?.first_name || ''} 
              </div>
              <ProfileCard
                profile={profile.payload}
                removeProfile={removeProfile}
                confirmationModal={confirmationModal}
                putProfile={putProfile}
                getProfile={getProfile}
                showConfirmationModal={showConfirmationModal}
                welcomeModal={welcomeModal}
                showWelcomeModal={showWelcomeModal}
                tagging={tagging}
              />
            </StyledControl>
          )}
          {!file.hasOwnProperty('fileName') && (
            <StyledControl>
              <div className="label">
                {language.dropzone_button_text}
              </div>
              <MinDropzone
                addProfile={addProfile}
                profile={profile}
              />
            </StyledControl>
           )}
          </>
        )}
        {windowWidth !== 0 && (
             <JobList
              fetchJobs={fetchJobs}
              jobs={jobs}
              updateCurrentTab={updateCurrentTab}
              jobsCategory={jobsCategory}
              jobsCategory1={jobsCategory1}
              jobsCategory2={jobsCategory2}
              jobsCategory3={jobsCategory3}
              jobsCategory4={jobsCategory4}
              fetchJobsCategory={fetchJobsCategory}
              fetchJobsCategory1={fetchJobsCategory1}
              fetchJobsCategory2={fetchJobsCategory2}
              fetchJobsCategory3={fetchJobsCategory3}
              fetchJobsCategory4={fetchJobsCategory4}
              boardFilters={boardFilters}
              otherBoardFilters={otherBoardFilters}
              file={file}
            />
        )}
        {detectMobileAndTablet(windowWidth)  && windowWidth !== 0 && !confirmationModal && !welcomeModal && (
          <div className="search-box-toggler" onClick={() => setIsSearchBoxShown(true)}>
            <img src={togglerIcon} /> Affiner
          </div>
        )}
      </StyledWrapper>
    )
  }
)


export default Container
