import React, { useState, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { Checkbox } from 'react-input-checkbox'
import 'react-input-checkbox/lib/react-input-checkbox.min.css'
import boxIcon from "content/images/box.svg"
import checkboxIcon from "content/images/checkbox.svg"

const StyledDropdown = styled.div`
  position: relative;
`

const StyledLabel = styled.div`
  padding: 0.4rem 0.8rem;
  height: 2.3528rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-bottom-left-radius: ${props => props.isOpen ? '0' : '0.23528rem'};
  border-bottom-right-radius: ${props => props.isOpen ? '0' : '0.23528rem'};
  border-top-right-radius: 0.23528rem;
  border-top-left-radius: 0.23528rem;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: #9CB6D0;
  font-size: 0.76466rem;
  line-height: 0.94112rem;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  font-weight: 300;
`
const StyledMenu = styled.div`
  position: absolute;
  top: 73%;
  left: 0px;
  right: auto;
  width: 100%;
  margin-top: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 3px 0.5882rem;
  z-index: 200;
  padding: 1rem;
  max-height: 20rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  transition: all 0.2s cubic-bezier(0.41, 0.094, 0.54, 0.07) 0s;
  border-radius: 0px 0px 0.2941rem 0.2941rem;
  transform: translateY(0.5882rem);
  overflow-y: auto;
  color: ${({ theme }) => theme.colors.textSecondary2};
  &::-webkit-scrollbar {
    width: 0.35292rem;
    background: #E4E4EB;
    border-radius: 5.882rem;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: #E4E4EB;
    border-radius: 5.882rem;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 5.882rem;
  }
  .menu-list {
    margin: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    .fancy-checkbox__image {
      width: 0.82348rem!important;
      height: 0.82348rem!important;
      margin-left: -1.4705rem!important;
      background: url(${boxIcon}) no-repeat 0 0;
    }
    
    .fancy-checkbox__input:disabled ~ .fancy-checkbox__label, .fancy-checkbox__input:disabled ~ .fancy-checkbox__image {
      opacity: 0.3;
      cursor: not-allowed;
    }
    .fancy-checkbox__input:checked + .fancy-checkbox__image {
      background: url(${checkboxIcon}) no-repeat 0 0;
      background-size: 0.82348rem;
    }
  }
`

const StyledInput = styled.input`
  border: none;
  outline: none;
  width: 100%
`

const ItemOptions = ({ item, selected, selectedItems,  onChangeSelected = () => {} }) => {
  const disabledClass = (Array.isArray(selectedItems) && !selected) || 
    (selectedItems.length === 1  && selected) ? true : false
  return (
    <Checkbox 
      className={disabledClass}
      theme="fancy-checkbox"
      value={selected}
     //disabled={maxItems ? disabledClass : false}
      onChange={onChangeSelected}
    >
      {item.label ?  item.label : item}
    </Checkbox>
  )
}

const Dropdown = props => {
  const { selectedItems, onChangeSelected } = props;
  const [activeState, setActiveState] = useState(false);
  const [options, setOptions] = useState(props.options);
  const handleSearchOption = (event) => {
    setActiveState(true)
    const { value } = event.target;
    const filteredOptions = props.options.filter(option => option.toLowerCase().includes(value.toLowerCase()));
    setOptions(filteredOptions);
  }
  return (
    <StyledDropdown>
      <StyledLabel
        onClick={ () => 
          !activeState && window.setTimeout(() => {
            setActiveState(prevActiveState => !prevActiveState)
          }, 10)
        }
        role="button"
        tabIndex="0"
        isOpen={activeState}
      >
        {props.isSearchable ? 
          <StyledInput
            type="text"
            placeholder={props.title}
            onChange={(event) => handleSearchOption(event)}
          /> 
          : props.title
        } 
        <FontAwesomeIcon className="icon-right" icon={activeState ? faChevronUp : faChevronDown} />

      </StyledLabel>
      {activeState && (
        <StyledMenu>
          <OutsideClickHandler
            onOutsideClick={ () => 
              window.setTimeout(() => {
                setActiveState(prevActiveState => !prevActiveState)
              }, 10)
            }
          >
            <ul className="menu-list">
              {options.sort().map((option, index) => {
                let selected = false;
                if (typeof option === 'string') {
                  selected = selectedItems.indexOf(option) !== - 1 ? true : false
                } else {
                  selected = JSON.stringify(selectedItems) === JSON.stringify(option) ? true : false
                }
                
                return (
                  <li
                    key={`option-${index}`}>
                    <ItemOptions
                      item={option}
                      selected={selected}
                      selectedItems={selectedItems}
                      onChangeSelected={() => onChangeSelected(option)}
                    />
                  </li>
                )
              })}
            </ul>
          </OutsideClickHandler>
        </StyledMenu>
      )}
    </StyledDropdown>
  )
}

export default Dropdown;