import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import { fetchJobs, fetchJobsCategory, fetchJobsCategory1, fetchJobsCategory2, fetchJobsCategory3, fetchJobsCategory4, updateCurrentTab } from "store/actions/jobs"
import { addProfile, removeProfile, getProfile, showConfirmationModal, showWelcomeModal, putProfile } from "store/actions/profile"
import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import { siteTitle } from "config"
import Container from "components/jobs/container"
import { isSSR } from 'utils'

const JobsPage = React.memo(
  ({
    data,
    addProfile,
    removeProfile,
    fetchJobs,
    fetchJobsCategory,
    fetchJobsCategory1,
    fetchJobsCategory2,
    fetchJobsCategory3,
    fetchJobsCategory4,
    jobs,
    jobsCategory,
    jobsCategory1,
    jobsCategory2,
    jobsCategory3,
    jobsCategory4,
    queryObject,
    boardFilters,
    profile,
    updateCurrentTab,
    currentTab,
    file,
    getProfile,
    showConfirmationModal,
    confirmationModal,
    welcomeModal,
    showWelcomeModal,
    putProfile,
    tagging
  }) => {
    const { frontmatter } = data.index.edges[0].node
    const { useSplashScreen } = frontmatter
    const globalState = {
      // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
      isIntroDone: useSplashScreen ? false : true,
      // darkMode is initially disabled, a hook inside the Layout component
      // will check the user's preferences and switch to dark mode if needed
      darkMode: true,
    }

    useEffect(() => {
      if(!isSSR) {
        setTimeout(() => {
          window.analytics && window.analytics.page();
         }, 100)
      }
    }, [])

    let otherTab = ""
    if (file && file.fileName) {
      otherTab = currentTab === 'scoring' ? 'searching' : 'scoring'
    } else {
      otherTab = currentTab === 'created_at' ? 'searching' : 'created_at'
    }
    return (
      <GlobalStateProvider initialState={globalState}>
        <Layout>
          <SEO
            title={`${siteTitle} | Nos offres d'emploi`}
          />
           <Container 
              putProfile={putProfile}
              getProfile={getProfile}
              fetchJobs={fetchJobs}
              jobs={jobs}
              jobsCategory={jobsCategory}
              jobsCategory1={jobsCategory1}
              jobsCategory2={jobsCategory2}
              jobsCategory3={jobsCategory3}
              jobsCategory4={jobsCategory4}
              fetchJobsCategory={fetchJobsCategory}
              fetchJobsCategory1={fetchJobsCategory1}
              fetchJobsCategory2={fetchJobsCategory2}
              fetchJobsCategory3={fetchJobsCategory3}
              fetchJobsCategory4={fetchJobsCategory4}
              file={file}
              addProfile={addProfile}
              profile={profile}
              boardFilters={boardFilters[currentTab]}
              otherBoardFilters={boardFilters[otherTab]}
              removeProfile={removeProfile}
              currentTab={currentTab}
              updateCurrentTab={updateCurrentTab}
              confirmationModal={confirmationModal}
              showConfirmationModal={showConfirmationModal}
              welcomeModal={welcomeModal}
              showWelcomeModal={showWelcomeModal}
              tagging={tagging}
            />
        </Layout>
      </GlobalStateProvider>
    )
  }
)

JobsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  jobs: state.jobs.jobs,
  jobsCategory: state.jobs.jobsCategory,
  jobsCategory1: state.jobs.jobsCategory1,
  jobsCategory2: state.jobs.jobsCategory2,
  jobsCategory3: state.jobs.jobsCategory3,
  jobsCategory4: state.jobs.jobsCategory4,
  queryObject: state.jobs.queryObject,
  boardFilters: state.jobs.boardFilters,
  currentTab: state.jobs.currentTab,
  profile: state.profile.profile,
  file: state.profile.file,
  confirmationModal: state.profile.confirmationModal,
  welcomeModal: state.profile.welcomeModal,
  tagging: state.profile.tagging
})

export default connect(mapStateToProps, { fetchJobs, fetchJobsCategory, fetchJobsCategory1, fetchJobsCategory2, fetchJobsCategory3, fetchJobsCategory4, addProfile, putProfile, removeProfile, getProfile, updateCurrentTab, showConfirmationModal, showWelcomeModal })(JobsPage)

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/index/index/" } }) {
      edges {
        node {
          frontmatter {
            seoTitle
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    drop: allMdx(filter: { fileAbsolutePath: { regex: "/index/drop/" } }) {
      edges {
        node {
          frontmatter {
            title
            titlePrefix
            subtitle
            subtitlePrefix
          }
        }
      }
    }
  }
`
