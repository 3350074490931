import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Tab from './tab'

const StyledTabs = styled.div`
  .tab-list {
    .tab-list-item {
      &:nth-of-type(2) {
        order: ${props => props.scoringActive ? '3' : 'unset'};
      }
    }
  }
`

const TabContent = styled.div`
  padding: 2rem 0;
  padding-bottom: 4rem;
  overflow: scroll;
  background: ${({ theme }) => theme.colors.background};
  width: ${props => props.maxWidth ? "100%" : "unset"};
  border: 1px solid ${({ theme }) => theme.colors.border};
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-auto-rows: auto;
    max-height: ${props => props.minHeight || '30rem'};
    min-height: 100%;
    height: 100%;
    overflow: hidden;
  }
`
const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(props.children[0].props.label)
  const { minHeight, maxHeight, maxWidth } = props;
  useEffect(() => {
    setActiveTab(props.activeTab)
  }, [props.activeTab])
  const onClickTabItem = (tab, sorting) => {
    setActiveTab(tab);
    props.tabChanged && props.tabChanged(sorting)
  }
  return (
    <StyledTabs className="tabs" scoringActive={props.scoringActive}>
      <ol className="tab-list">
        {props.children.map((child) => {
          const { label, icon, sorting, isShowing } = child.props;
          if(isShowing) {
            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
                icon={icon}
                sorting={sorting}
              />
            );
          }
          return null
        })}
      </ol>
      <TabContent minHeight={minHeight} maxHeight={maxHeight} maxWidth={maxWidth} className="nopadding">
        {props.children.map((child) => {
          if (child.props.label !== activeTab || !child.props.isShowing) return undefined;
          return child.props.children;
        })}
      </TabContent>
    </StyledTabs>
  );
}

export default Tabs