import React, {useState, useEffect, useRef} from "react"
import styled from "styled-components"
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faClock, faCompass } from '@fortawesome/free-regular-svg-icons'
import JobCarousel from "components/jobs/jobCarousel"
import Loader from "components/loader"
import ErrorMessage from "components/errorMessage"
import Tabs from "./tabs"
import {language} from "config"


const StyledDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  background:  ${({ theme }) => theme.colors.background};
  padding-top: 2rem;
  border-top-left-radius: 1rem;
`

const StyledButton = styled.a`
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  display: flex;
  justify-content: center;
  margin: 0;
  width: 100%;
  margin: 0 auto;
  padding: 0.76466rem;
  border-radius: 5.1px;
  width: 10rem;
  margin-top: 2rem;
  &:hover {
    filter: unset;
    transform: unset;
    box-shadow: unset;
    opacity: 0.8;
  }
`

const JobList = React.memo(
  props => {
    const [activeTab, setActiveTab] = useState(null)
    const [searchingShown, setSearchingShown] = useState(null)
    const isMounted = useRef(true);

    useEffect(() => {
      const sorting = props.boardFilters.sortBy
      switch(sorting) {
        case 'crated_at':
        setActiveTab("Les nouveautés")
        break;
  
        case 'scoring':
        setActiveTab("Moteur de suggestions d'offres")
        break;
  
        case 'searching':
        setActiveTab("Moteur de recherche d'offres")
        break;
        
        default: 
        setActiveTab("Les nouveautés")
      }
     /*  const isShown = props.boardFilters && (props.boardFilters.skills.enabled.length > 0 || (props.boardFilters.jobs.length > 0 && props.boardFilters.jobs.filter(j => j.checked === true).length > 0))
      if(!isShown) {
        setSearchingShown(isShown)
        // setActiveTab("Les nouveautés")
      } else {
        setSearchingShown(isShown)
        if(isMounted.current) {
          setActiveTab("Moteur de recherche d'offres")
          isMounted.current = false
        }
      } */
    }, [props.boardFilters])
    
    const tabChanged = value => {
      const newBoardFilters = {...props.otherBoardFilters, sortBy: value}
      props.updateCurrentTab(value);
      props.fetchJobs({boardFilters: newBoardFilters, page: 1, init: true, tabChanged: ""})
    }


    return (
      <StyledDiv>
        {props.jobs.s && props.jobs.payload.jobs.length > 0 && (
          <>
            {props.boardFilters.categories.map(cat => {

              return (
                <JobCarousel
                  title={cat.value}
                  jobs={props.jobsCategory}
                  category={cat}
                  fetchJobs={props.fetchJobsCategory}
                  boardFilters={props.boardFilters}
                  sorting={props.file && props.file.fileName ? 'scoring' : 'created_at'}
                />
              )
            })}
           
          </>
        )}
        {props.jobs.s && props.jobs.payload.jobs.length === 0 && (
          <ErrorMessage 
            title={language.empty_message_title}
            subtitle={language.empty_message_subtitle}
            type="empty"
          />
        )}
        {props.jobs.r && (
          <Loader />
        )}
        {props.jobs.f && (
          <>
            <ErrorMessage 
              title={language.error_message_title}
              subtitle={language.error_message_subtitle}
              type="error"
            />
            <StyledButton
              onClick={() => props.fetchJobs({boardFilters: props.boardFilters, init: true})}
              role="button"
            >
              {language.retry}
            </StyledButton>
            
          </>
        )}
      </StyledDiv>
    )
  }
) 

export default JobList;