import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { Checkbox } from "react-input-checkbox"
import "react-input-checkbox/lib/react-input-checkbox.min.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons"
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import MinDropzone from "components/minDropzone"
import boxIcon from "content/images/box.svg"
import checkboxIcon from "content/images/checkbox.svg"
import SearchLocationInput from "./SearchLocationInput"
import Dropdown from "./dropdown"
import ProfileCard from "./profileCard"
import { catalog , otherCatalogs, dateOptions, language} from "config"
const  contractsObject =  require('config/contracts.json')

const StyledWrapper = styled.div`
  //max-height: 100vh;
  overflow-x: hidden;
  position: fixed;
  z-index: 100;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding-bottom: 5.2938rem;
  background: ${({ theme }) => theme.colors.secondary};
  &::-webkit-scrollbar {
    width: 0.35292rem;
    background: #091420;
    border-radius: 5.882rem;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: #091420;
    border-radius: 5.882rem;
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 5.882rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    //max-height: calc(100vh - 6.5rem);
    overflow-y: hidden;
    z-index: 0;
    position: unset;
    background: unset;
    padding-bottom: 0;
    &:hover {
      overflow-y: overlay;
    }
  }
`
const StyledControl = styled.div`
  padding: 1.1764rem 1.64696rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  &:last-of-type {
    border-bottom: none;
  }

  .label {
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 0.94112rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: 0.64702rem;
  }

  .input {
    height: 2.3528rem;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 0.23528rem;
    background: #FFFFFF;
    position: relative;
    outline: none;
    width: 100%;
    padding-left: 0.8823rem;
    ::placeholder {
      color: #9CB6D0;
      font-size: 0.76466rem;
      line-height: 0.94112rem;
    }
  }

  .list {
    margin-top: 0.64702rem;
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: 0.82348rem;
    line-height: 0.94112rem;
    .fancy-checkbox__image {
      width: 0.82348rem!important;
      height: 0.82348rem!important;
      margin-left: -1.4705rem!important;
      background: url(${boxIcon}) no-repeat 0 0;
    }
  
    .fancy-checkbox__input:checked + .fancy-checkbox__image {
      background: url(${checkboxIcon}) no-repeat 0 0;
      background-size: 0.82348rem;
    }
  }
  .rc-slider-rail {
    background-color: #fff;
  }
  .rc-slider-track {
    background-color: ${({ theme }) => theme.colors.primary};
  }
  .creatables {
    margin-top: 0.64702rem;
    .choices {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0 0 0.5rem;
      .choice {
        background: ${({ theme }) => theme.colors.primary};
        border-radius: 5.1px;
        margin: 0.23528rem 0.35292rem 2px 0;
        padding: 0.2941rem 0.47056rem;
        font-size: 0.71rem;
        transition: .4s;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: white;
        z-index: 0;
        &.locked {
          opacity: 0.8;
          pointer-events: none;
        }
      }
      .disabled {
        background: transparent;
        border: 1px solid ${({ theme }) => theme.colors.subtext};
        color: ${({ theme }) => theme.colors.subtext};
      }
    }
  }
`

const StyledControlFixed = styled(StyledControl)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.tertiary};
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  width: calc(100% - 0.35292rem);
`

const StyledButton = styled.a`
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  display: flex;
  justify-content: center;
  margin: 0;
  width: 100%;
  margin: 0 auto;
  padding: 0.76466rem;
  border-radius: 5.1px;
  &:hover {
    filter: unset;
    transform: unset;
    box-shadow: unset;
    opacity: 0.8;
  }
`

const StyledBadge = styled.span`
  display: flex;
  flex-flow: row wrap;
  place-content: center;
  align-items: center;
  //position: absolute;
  box-sizing: border-box;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.55rem;
  min-width: 20px;
  line-height: 1;
  padding: 0px 6px;
  height: 20px;
  border-radius: 10px;
  z-index: 1;
  background-color: rgb(25, 118, 210);
  color: rgb(255, 255, 255);
  transform-origin: 100% 0%;
  margin: 0 0 0 0.3rem;
  
`

const SearchBox = props => {
  const [boardFilters, setBoardFilters] = useState({})
  const [location, setLocation] = useState(null)
  const [radius, setRadius] = useState(props.boardFilters?.location_distance || 70)
  const [selectedCategories, setSelectedCategories] = useState(props.boardFilters?.categories?.map(category => category.value))
  const [newSelectedCategory, setNewSelectedCategory] = useState(null)
  const [filtersShown, setFiltersShown] = useState(true)
  const [selectedTypes, setSelectedTypes] = useState([])
  const [selectedRangeDate, setSelectedRangeDate] = useState(dateOptions.items.filter(date => date.value === props.boardFilters?.date_range_min)[0])
  const isMounted = useRef(false);
  const isTypesMounted = useRef(false);
  
  useEffect(() => {
    if(isMounted.current) {
      let index = null
      let action = null
      if(newSelectedCategory && newSelectedCategory.position === -1) {
        index = selectedCategories.indexOf(newSelectedCategory.value)
        action = "add"
      } else {
        index = newSelectedCategory.position
        action = "delete"
      }

      const categories = selectedCategories.map(category =>  {
        return { name: catalog.key , value: category }
      })


      props.fetchJobsCategory({
        boardFilters: {
          ...props.boardFilters,
          categories,
        },
        category: newSelectedCategory,
        page: 1,
        action,
      })
      
    } else {
      isMounted.current = true;
    }
  }, [selectedCategories])
  
  useEffect(() => {
    if(isTypesMounted.current) {
      props.fetchJobs({
       boardFilters: {
        ...props.boardFilters,
        types: selectedTypes.map(type =>  {
          return { name: 'talentsoft-contractType-id', value: type }
        }),
        init: true,
       }
      })
    } else {
      isTypesMounted.current = true;
    }
  }, [selectedTypes])

  useEffect(() => {
    setSelectedCategories(props.boardFilters?.categories?.map(category => category.value))
    isMounted.current = false;
  }, [props.boardFilters])

  useEffect(() => {
    setBoardFilters(props.boardFilters)
    setSelectedTypes(props.boardFilters.types.map(type => type.value))
    isTypesMounted.current = false
  }, [props.boardFilters])

  useEffect(() => {
    if(location) {
      const newBoardFilters = {...boardFilters}
      if(newBoardFilters.hasOwnProperty('locations')) {
        newBoardFilters.locations.forEach(item => item.checked = false);
        newBoardFilters.locations.unshift({...location, checked: true});
      }
      setBoardFilters(newBoardFilters)
      props.fetchJobs({boardFilters: newBoardFilters, page: 1, init: false})
    }
  }, [location])

  const inputSkillsRef = useRef(null);
  const inputExperiencesRef = useRef(null);

  const handleCreatableKeyPress = (event) => {
    const value = event.target.value
    const name = event.target.name
    if (event.key === 'Enter') {
      const newBoardFilters = {...boardFilters}
      newBoardFilters[`${name}`].enabled.unshift(value)
      setBoardFilters(newBoardFilters)
      inputSkillsRef.current.value = null
      props.fetchJobs({boardFilters: newBoardFilters, page: 1, init: false})
    }
  }

  const deleteItem = (name, item) => {
    const newBoardFilters = {...boardFilters}
    newBoardFilters[`${name}`].enabled = newBoardFilters[`${name}`].enabled.filter(f => {
      return item !== f
    })
    newBoardFilters[`${name}`].disabled.unshift(item)
    setBoardFilters(newBoardFilters)
    props.fetchJobs({boardFilters: newBoardFilters, page: 1, init: false})
  }

  const addItem = (name, item) => {
    const newBoardFilters = {...boardFilters}
    newBoardFilters[`${name}`].enabled.unshift(item)
    newBoardFilters[`${name}`].disabled = newBoardFilters[`${name}`].disabled.filter(f => {
      return item !== f
    })
    setBoardFilters(newBoardFilters)
    props.fetchJobs({boardFilters: newBoardFilters, page: 1, init: false})
  }

  const onChangeSelected = (name, value, index) => {
    const newBoardFilters = {...boardFilters}
    
    if(name === 'locations') {
      newBoardFilters[`${name}`].forEach(item => item.checked = false)
    }

    newBoardFilters[`${name}`][index].checked = value;
    setBoardFilters(newBoardFilters)
    props.fetchJobs({boardFilters: newBoardFilters, page: 1, init: false})
  }
  const handleChangeLocationInput = (location) => {
    setLocation(location)
  } 

  const handleChangeRadius = (value) => {
    setRadius(value)
  }

  const handleAfterChangeRadius  = (value) => {
    const newBoardFilters = {...boardFilters}
    newBoardFilters.location_distance = value
    setBoardFilters(newBoardFilters)
    props.fetchJobs({boardFilters: newBoardFilters, page: 1, init: false})
  }

  const handleCheckableKeyPress = (event) => {
    const value = event.target.value
    const name = event.target.name
    if (event.key === 'Enter') {
      const newBoardFilters = {...boardFilters}
      if (name === 'locations') {
        newBoardFilters[`${name}`].forEach(item => item.checked = false)
      } else {
        newBoardFilters[`${name}`].unshift({text: value, checked: true})
      }
      setBoardFilters(newBoardFilters)
      props.fetchJobs({boardFilters: newBoardFilters, page: 1, init: false})
      inputExperiencesRef.current.value = null
    }
  }

  const ChangeSelectedCategriesHandler = item => {
    const newItem = {value: item, position: selectedCategories.indexOf(item)}
    setNewSelectedCategory(newItem)
    setSelectedCategories(prevCategories => {
      if ([...prevCategories].indexOf(item) === - 1) {
        return [
          item,
          ...prevCategories,
        ]
      }
      return [...prevCategories].filter(cat => cat !== item )
    })
  }

  const ChangSelectedTypesHandler = item => {

    setSelectedTypes(prevTypes => {
      if ([...prevTypes].indexOf(item) === - 1) {
        return [
          ...prevTypes,
          item
        ]
      }
      return [...prevTypes].filter(type => type !== item )
    })
  }

  const detectFile = value => {
    if(value) {
      setFiltersShown(false)
    } else {
      setFiltersShown(true)
    }
  }

  const ChangeSelecedDateRangeHandler = item => {
    setSelectedRangeDate(item)
    const newBoardFilters = {...boardFilters, date_range_min: item.value }
    props.fetchJobs({boardFilters: newBoardFilters, page: 1, init: false})
  }

  return (
    <StyledWrapper>
      {!props.isMobile && (
        <StyledControl>
          <div className="label">
            {props.file && props.file.fileName ? `Bienvenue ${props.profile?.payload?.info?.first_name || ''} `  : filtersShown ? language.dropzone_button_text : language.consent_header} 
          </div>
          {props.file && props.file.fileName ? (
            <ProfileCard
              profile={props.profile.payload}
              loaded={props.profile.s}
              removeProfile={props.removeProfile}
              detectFile={detectFile}
              confirmationModal={props.confirmationModal}
              showConfirmationModal={props.showConfirmationModal}
              welcomeModal={props.welcomeModal}
              showWelcomeModal={props.showWelcomeModal}
              putProfile={props.putProfile}
              getProfile={props.getProfile}
              tagging={props.tagging}
            />
          )
          :
          (
            <MinDropzone
              addProfile={props.addProfile}
              profile={props.profile}
              detectFile={detectFile}
            />
          )
          }
        </StyledControl>
      )}
      <>
        {(filtersShown || (props.file && props.file.fileName)) && !props.profile.r  ? (
          <>
            <StyledControl>
              <div className="label">
                {language.location_label}
              </div>
              <SearchLocationInput
                changeLocation={location => handleChangeLocationInput(location)}
              />
              <div className="list">
                {boardFilters?.locations?.map((location, index) => {
                  return (
                    <Checkbox 
                      key={`loc-${index}`}
                      theme="fancy-checkbox"
                      value={location.checked}
                      onChange={() => onChangeSelected('locations', !location.checked, index)}
                    >
                      {location.text}
                    </Checkbox>
                  )
                })}
              </div>
            </StyledControl>
            {boardFilters?.locations?.filter(loc => loc.checked === true).length > 0 &&
              (
                <StyledControl>
                  <div className="label">Rayon ({radius}km)</div>
                  <Slider
                    onChange={value => handleChangeRadius(value)}
                    onAfterChange={value => handleAfterChangeRadius(value)}
                    value={radius}
                    min={5}
                    max={500}
                  />
                </StyledControl>
              )
            }
            <StyledControl>
              <div className="label">
              {language.jobtitle_label}

              </div>
              <input
                type="text"
                placeholder={language.jobtitle_placeholder}
                onKeyPress={event => handleCheckableKeyPress(event)}
                name="jobs"
                ref={inputExperiencesRef}
                className="input"
              />
              <div className="list">
                {boardFilters?.jobs?.map((experience, index) => {
                  return (
                    <Checkbox
                      key={`exp-${index}`}
                      theme="fancy-checkbox"
                      value={experience.checked}
                      onChange={() => onChangeSelected('jobs', !experience.checked, index)}
                    >
                      {experience.text}
                    </Checkbox>
                  )
                })}
              </div>
            </StyledControl>
            {catalog.key && (
                <StyledControl>
                  <div className="label">
                    {catalog.title}
                  </div>
                  <Dropdown
                    options={catalog.items}
                    onChangeSelected={ChangeSelectedCategriesHandler}
                    selectedItems={selectedCategories}
                    title={catalog.placeholder}
                    isSearchable
                  />
                  <div className="creatables">
                    <ul className="choices">
                      {
                        selectedCategories.map((cat, index) => {
                          if(cat) {
                            return (
                              <li
                                key={`cat-${index}`}
                                className={`choice ${selectedCategories.length === 1 ? 'locked': ''}`}
                                onClick={() => selectedCategories.length > 1 ? ChangeSelectedCategriesHandler(cat) : undefined}
                              >
                                <FontAwesomeIcon className="mr-05" icon={faTimes} />
                                <span>{cat}</span>
                                
                                    {props.jobsCategory[cat]?.payload?.meta?.total ? (
                                      <StyledBadge>
                                        {props.jobsCategory[cat]?.payload?.meta?.total}
                                      </StyledBadge>
                                    ):(
                                      null
                                    )}
                                  
                              </li>
                            )
                          }
                          return null
                        })
                      }
                    </ul>
                  </div>
                </StyledControl>
            )}
            {otherCatalogs[0].key && (
              <StyledControl>
                <div className="label">
                  {otherCatalogs[0].title}
                </div>
                <Dropdown options={otherCatalogs[0].items} onChangeSelected={ChangSelectedTypesHandler} selectedItems={selectedTypes} title={otherCatalogs[0].placeholder} />
                <div className="creatables">
                  <ul className="choices">
                    {
                      selectedTypes.map((type, index) => {
                        return (
                          <li
                            key={`cat-${index}`}
                            className={`choice`}
                            onClick={() => ChangSelectedTypesHandler(type)}
                          >
                            <FontAwesomeIcon className="mr-05" icon={faTimes} />                  
                            <span>{type}</span>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </StyledControl>
            )}
            {dateOptions.hasOwnProperty('items') && (
              <StyledControl>
                <div className="label">
                  {dateOptions.title}
                </div>
                <Dropdown options={dateOptions.items} onChangeSelected={ChangeSelecedDateRangeHandler} selectedItems={selectedRangeDate} title={selectedRangeDate && selectedRangeDate.label} />
              </StyledControl>
            )}
            <StyledControl>
              <div className="label">
                {language.skills_label }
              </div>
              <input
                type="text"
                className="input"
                placeholder={language.skills_label}
                onKeyPress={event => handleCreatableKeyPress(event)}
                ref={inputSkillsRef}
                name="skills"
              />
              <div className="creatables">
                <ul className="choices">
                  {
                    boardFilters.skills?.enabled?.map((skill, index) => {
                      return (
                        <li
                          key={`skill-${index}`}
                          className="choice"
                          onClick={() => deleteItem('skills',skill)}
                        >
                          <FontAwesomeIcon className="mr-05" icon={faTimes} />                  
                          <span>{skill}</span>
                        </li>
                      )
                    })
                  }
                </ul>
                <ul className="choices">
                    {
                      boardFilters?.skills?.disabled?.map((skill, index) => {
                        return (
                          <li
                            key={`skill-${index}`}
                            className="choice disabled"
                            onClick={() => addItem('skills',skill)}
                          >
                            <FontAwesomeIcon className="mr-05" icon={faPlus} />
                            <span>{skill}</span>
                          </li>
                        )
                      })
                    }
                </ul>
              </div>
            </StyledControl>
          </>
        )
        :
          null
        }
      </>

      {props.isMobile && (
        <StyledControlFixed>
          <StyledButton onClick={() => props.hideSearchBox()}>
            Fermer
          </StyledButton>
        </StyledControlFixed>
      )}
      
    </StyledWrapper>
  )
}

export default SearchBox